import React from "react"
import { featured } from "../../data/Data"
import { useHistory } from "react-router-dom";
import image from "./h1.png";
import image2 from "./h2.png";
import image3 from "./h3.png";
import image4 from "./h4.png";
import image5 from "./h6.png";

const FeaturedCard = () => {
  const history = useHistory();
  const routeChange = () =>{ 
    let path = `/backup`; 
    history.push(path);
  }
  const routeChange2 = () =>{ 
    let path = `/cloud-services`; 
    history.push(path);
  }
  const routeChange3 = () =>{ 
    let path = `/network`; 
    history.push(path);
  }
  const routeChange4 = () =>{ 
    let path = `/security`; 
    history.push(path);
  }
  const routeChange5 = () =>{ 
    let path = `/monitoring`; 
    history.push(path);
  }

  return (
    <>
    
      <div className='content grid5 mtop'>
        {/* {featured.map((items, index) => ( */}
          <div className='grow' onClick={routeChange}>
          <div className='box'>
            <img src={image} alt='' />
            <h4>Backup & Recovery</h4>
          </div>
          {/* <label>{items.more}</label> */}
          
          </div>

          <div className='grow' onClick={routeChange2}>
          <div className='box'>
            <img src={image2} alt='' />
            <h4>Cloud Services</h4>
          </div>
          {/* <label>{items.more}</label> */}
          
          </div>

          <div className='grow' onClick={routeChange3}>
          <div className='box'>
            <img src={image3} alt='' />
            <h4>Network Services</h4>
          </div>
          {/* <label>{items.more}</label> */}
          
          </div>

          <div className='grow' onClick={routeChange4}>
          <div className='box'>
            <img src={image4} alt='' />
            <h4>Security & Anti Virus</h4>
          </div>
          {/* <label>{items.more}</label> */}
          
          </div>

          <div className='grow' onClick={routeChange5}>
          <div className='box'>
            <img src={image5} alt='' />
            <h4>Managed Services</h4>
          </div>
          {/* <label>{items.more}</label> */}
          
          </div>
        {/* ))} */}
      </div>
    </>
  )
}

export default FeaturedCard
