import React, { useState, useEffect, useRef } from "react"
import "./header.css"

// Commented out

// import { nav } from "../../data/Data"

// Commented out

import { Link } from "react-router-dom"
import { useHistory } from "react-router-dom";
import ClickAwayListener from 'react-click-away-listener';

const Header = () => {
  const [navList, setNavList] = useState(false)
  const btnRef = useRef();
  const url = 'https://bookit.dialageek.ca/userDashboard/bookNow/';
  const url2 = 'https://bookit.dialageek.ca/register/';
  const history = useHistory();
  const homePage = () => {
    history.push('/')
  }
  const handleClickAway = () => {
		setNavList(false);
	};
  useEffect(() => {
    const closeDropdown = e => {
      if (e.path[0] !== btnRef.current) {
        setNavList(false);
      }
    }
    document.body.addEventListener('click', closeDropdown);
    document.addEventListener('touchstart', closeDropdown)
    return () => document.body.removeEventListener('click', closeDropdown)
  })
  return (
    <>
    <ClickAwayListener onClickAway={handleClickAway}>
      <header>
        <div className='flex'>
          <div className='logo'>
              <div className='flex-logo-text'>
                <img src='/images/logo.png' alt='' onClick={homePage} className='mainlogo'/>
                  <h4 className='logo-title'><Link to='/' className='logo-title-text'>Dial-A-Geek Consulting</Link></h4>
                    <h1 className='divider'>|</h1>
                      <div className='nav'>
                        <ul className={navList ? "small" : "flex"}>

                          {/* {nav.map((list, index) => ( */}
                            <li className='listitem'>
                              {/* <Link to={list.path}>{list.text}</Link> */}
                              <Link to="/">Home</Link>
                              <Link to="/about">About</Link>
                              <Link to="/services">Services</Link>
                              <Link to="/contact-us">Contact</Link>

                            </li> 

                          {/* ))} */}
                        </ul>
                      </div>
                </div>
            </div>
          {/* <div className='nav'>
          </div> */}
          <div className='button flex'>
            {/* <h4>
              <span>2</span> My List
            </h4> */}
            <button className='btn1' onClick={() => window.open(url, '_self')}>
            <i className="fa fa-solid fa-circle-user"></i> Sign In
            </button>
            <button className='btn1' onClick={() => window.open(url2, '_self')}>
              <i className='fa fa-solid fa-book-bookmark'></i> Register
            </button>
          </div>


          <div className='toggle'>
            <button ref={btnRef} onClick={() => {
              setNavList(!navList)
            }}>
            {navList ? <span className='toggleicon'><i className='fa fa-times'></i></span> : <span className='toggleicon'><i className='fa fa-bars'></i></span>}</button>

          </div>



        </div>
      </header>
      </ClickAwayListener>
    </>
  )
}

export default Header
