import React from "react"
import Header from "../common/header/Header"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Home from "../home/Home"
import Footer from "../common/footer/Footer"
import About from "../about/About"
import Pricing from "../pricing/Pricing"
import Blog from "../blog/Blog"
import Services from "../services/Services"
import Contact from "../contact/Contact"
import Rates from "../rates/Rates"
import Terms from "../terms/Terms"
import Service1 from "../service1/Service1"
import Service2 from "../service2/Service2"
import Service3 from "../service3/Service3"
import Service4 from "../service4/Service4"
import Service5 from "../service5/Service5"

const Pages = () => {
  return (
    <>
      <Router>
        <Header />

        <Switch>

          <Route exact path='/' component={Home} />
          
          <Route exact path='/about' component={About} />

          <Route exact path='/services' component={Services} />

          <Route exact path='/blog' component={Blog} />

          <Route exact path='/pricing' component={Pricing} />

          <Route exact path='/contact-us' component={Contact} />

          <Route exact path='/rates' component={Rates} />

          <Route exact path='/terms' component={Terms} />

          <Route exact path='/backup' component={Service1} />
          <Route exact path='/cloud-services' component={Service2} />
          <Route exact path='/network' component={Service3} />
          <Route exact path='/security' component={Service4} />
          <Route exact path='/monitoring' component={Service5} />

          
        </Switch>

        <Footer />
      </Router>
    </>
  )
}

export default Pages
