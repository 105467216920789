import React from "react"
import img from "../images/pricing.jpg"
import Back from "../common/Back"
import "./contact.css"
import GoToTop from "../common/GoToTop"

const Contact = () => {
  // const sendEmail= (e) => {
  //   e.preventDefault();
  //   alert("submitted");
  // }
  return (
    <>
      <section className='contact mb'>
        <Back name='Contact Us' title='Get Fast Help' cover={img} />
        <div className='squeeze'>
        <div className='container flex'>
          <form className='shadow' action="https://formsubmit.co/c01c54549d577724890a30db21d4bb5e" method="POST">            
          <h4>Please fill out the form <br/>
            An agent will respond as soon as possible.</h4> <br />
            <div>
              <input type='text' placeholder='Name' />
              <input type='text' placeholder='Email' />
            </div>
            <input type='text' placeholder='Subject' />
            {/* <input type="hidden" name="_captcha" value="false"/> */}
            {/* <input type='text' placeholder='Message' className='messageInput' /> */}
            <textarea cols="" rows="5" wrap="off" required autofocus placeholder="Message" className='messagebox'></textarea>
            <button>Submit Request</button>
          </form>
        </div>
        </div>
      </section>
      <GoToTop />
    </>
  )
}

export default Contact
