import React from "react"
import Back from "../common/Back"
import GoToTop from "../common/GoToTop"
import Heading from "../common/Heading"
import img from "../images/about.jpg"
import "./service2.css"
import { useHistory } from "react-router-dom";

const Service2 = () => {
  const url = 'https://bookit.dialageek.ca/';
  const history = useHistory();
  const routeChange = () =>{ 
    let path = `/services`; 
    history.push(path);
  }
  return (
    <>
      <section className='about'>
        <Back name='Cloud Services' title='Modern Solutions' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            
          <div className='backbuttoncontainer'>
              <button className='backbutton' onClick={routeChange}>
              <p><i class="fa-solid fa-circle-arrow-left fa-2xl"></i><span className='backbuttontext'>Back to Services</span></p>
              </button>
            </div>
            <Heading title='Cloud Services' subtitle='Scalable, reliable, affordable, & flexible options' />
            <p className='backupText'>Virtual servers are an efficient way to deploy servers for your business.<br/><br/>
              Dial-A-Geek can fully manage your Canadian hosted virtual servers, and can scale the power and performance to meet your exact needs.
              <br/><br/>Need to store physical servers offsite? No problem, with Dial-A-Geek we can provide access to secure offsite servers to meet any demand.
              </p>
            <button onClick={() => window.open(url, '_self')} className='btn2'>Get Support</button>
          </div>
          <div className='right row'>
            <img src='./images/network.png' alt='' className='services2image' />
          </div>
        </div>
      </section>
      <GoToTop />
    </>
  )
}

export default Service2
