import React from "react"
import Back from "../common/Back"
import GoToTop from "../common/GoToTop"
import Heading from "../common/Heading"
import img from "../images/about.jpg"
import "./service1.css"
import { useHistory } from "react-router-dom";

const Service1 = () => {
  const url = 'https://bookit.dialageek.ca/';
  const history = useHistory();
  const routeChange = () =>{ 
    let path = `/services`; 
    history.push(path);
  }
  return (
    <>
      <section className='about'>
        <Back name='Backup & Recovery' title='Data Protection' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            
          <div className='backbuttoncontainer'>
              <button className='backbutton' onClick={routeChange}>
              <p><i class="fa-solid fa-circle-arrow-left fa-2xl"></i><span className='backbuttontext'>Back to Services</span></p>
              </button>
            </div>
            <Heading title='Backup & Recovery' subtitle='Scalable, reliable, affordable, & flexible options' />
            <p className='backupText'>At Dial-A-Geek, we’ve designed tailor made packages for all sizes of businesses.
                <br/>Our backups are configured with the latest technologies to ensure availability. <br/><br/>Backup features include:<br/><br/>
                • <b>Automation</b> <br/>
                • <b>Reports all failures via e-mail</b> <br/>
                • <b>Backup data/SQL/Exchange or SharePoint</b> <br/>
                • <b>Bare-metal or virtual machine (VM) recovery</b> <br/>
                • <b>Fast & Reliable</b> <br/>
                • <b>Stored securely in Canada</b> <br/>
                • <b>HIPAA, SOX and GLBA compliant</b> <br/>

              </p>
            <button onClick={() => window.open(url, '_self')} className='btn2'>Get Support</button>
          </div>
          <div className='right row'>
            <img src='./images/backup.png' alt='' className='services1image'/>
          </div>
        </div>
      </section>
      <GoToTop />
    </>
  )
}

export default Service1
