import React from "react"
import Back from "../common/Back"
import GoToTop from "../common/GoToTop"
import Heading from "../common/Heading"
import img from "../images/about.jpg"
import "./rates.css"

const Rates = () => {
  const url = 'https://bookit.dialageek.ca/';
  return (
    <>
      <section className='about'>
        <Back name='Service Rates' title='Our Rates' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='Regular Repair Rates' subtitle='Gain access to Dial-A-Geek help as you need it' />
            <h3><i class="fa fa-thin fa-square-check"></i><span className='smheading'>Online Service</span></h3> 
              <h3><i class="fa fa-thin fa-square-check"></i><span className='smheading'>On-Site Service</span></h3><br/><br/>
              <div className="container flex">
              <div className='ratecard'>
              Standard Rate <br/>
              <b>$145*</b>
              </div>

              <div className='ratecard'>
              Seniors & Students Rate <br/>
              <b>$130*</b>
              </div>

              <div className='ratecard'>
              Charitable Rate <br/>
              <b>$130*</b>
              </div>
              </div>
            <p>* All appointments cancelled with less than 2 hours before the scheduled time will be invoiced a half hour cancellation fee</p>
            <p>* Hourly rate, minimum of 1 hour for on-demand support</p>
            
            <button onClick={() => window.open(url, '_self')} className='btn2'>Get Support</button>
          </div>
          
          <div className='right row'>
            <img className='rightphoto' src='./images/immio.jpg' alt='' />
          </div>
          
        </div>
        <div className="container">
        {/* <h2><br/>Real-Time Packages</h2> */}
            {/* <p>Buy Discount Blocks of computer-help that you can use gradually.<br/> No Expiry, No Rush, just Help when you need it! <br/>Work is billed in 30-minute increments — not hourly.</p> */}
            <div className="container flex">
              {/* <div className='ratecard2'>
              <b><span className='blocktitle'>5-Hour Block</span></b> <br/>
              $688.75**
              Billed per 30 minutes<br/>
              5% Discount<br/>
              Online Service<br/>
              On-Site Service
              </div>

              <div className='ratecard2'>
              <b><span className='blocktitle'>10-Hour Block</span></b> <br/>
              $1348.50**
              Billed per 30 minutes<br/>
              7% Discount<br/>
              Online Service<br/>
              On-Site Service
              </div> */}
              </div> 
                {/* <p>** Time block purchases are non-refundable and non-transferrable. */}
                {/* </p>   */}
                <h2><br/>Virus Protection</h2>
                <p>Dial-A-Geek Consulting is a registered re-seller of NOD32 Antivirus, ESET’s award winning anti-virus software.<br/></p>
                  <div className='ratecard2'>
                    <b><span className='blocktitle'>Virus Protection</span></b> <br/>
                    $49.99
                    Billed per year<br/>
                    Easy Install<br/>
                    Free Updates<br/>
                  </div>
                </div>
              </section>
              <GoToTop />
          </>
  )
}

export default Rates
