export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "about",
    path: "/about",
  },
  {
    text: "services",
    path: "/services",
  },
  // {
  //   text: "blog",
  //   path: "/blog",
  // },
  // {
  //   text: "pricing",
  //   path: "/pricing",
  // },
  {
    text: "contact",
    path: "/contact-us",
  },
  {
    text: "rates",
    path: "/rates",
  },
  {
    text: "terms",
    path: "/terms",
  },
]
export const featured = [
  {
    cover: "../images/hero/h1.png",
    name: "Backup & Recovery",
    more: "Get protected in case of disaster",
    path: "/backup",
  },
  {
    cover: "../images/hero/h2.png",
    name: "Cloud Services",
    more: "Connect devices to online resources",
    path: "/",
  },
  {
    cover: "../images/hero/h3.png",
    name: "Network Implementation",
    more: "Keep your business connected",
    path: "/",
  },
  {
    cover: "../images/hero/h4.png",
    name: "Security & Anti Virus",
    more: "Keep your business protected",
    path: "/",
  },
  {
    cover: "../images/hero/h6.png",
    name: "Network Monitoring & Management",
    more: "We can monitor for problems",
    path: "/",
  },
  
]
export const list = [
  {
    id: 1,
    cover: "../images/list/p-1.png",
    name: "Backup & Recovery",
    location: "Fully automated online data backup service",
    category: "Data Protection",
    price: "Get Started",
    // type: "Apartment",
  },
  {
    id: 2,
    cover: "../images/list/p-2.png",
    name: "PC, Laptop, & Mac Repair",
    location: "We can backup, reset & repair devices",
    category: "Reset PC",
    price: "Get Started",
    // type: "Condos",
  },
  {
    id: 3,
    cover: "../images/list/p-7.png",
    name: "Network Services",
    location: "Installations, repairs & upgrades",
    category: "Fast Help",
    price: "Get Started",
    // type: "Offices",
  },
  {
    id: 4,
    cover: "../images/list/p-4.png",
    name: "Security & Antivirus",
    location: "Protect your home or your business network",
    category: "Update Devices",
    price: "Get Help",
    // type: "Homes & Villas",
  },
  {
    id: 5,
    cover: "../images/list/p-5.png",
    name: "Monitoring & Management",
    location: "Stay connected, get alerted to issues quickly",
    category: "Inquire Today",
    price: "Get Help",
    // type: "Commercial",
  },
  {
    id: 6,
    cover: "../images/list/p-6.png",
    name: "General Tech Issues",
    location: "We take care of any issue, no matter how small",
    category: "We Fix It",
    price: "Get Help",
    // type: "Apartment",
  },
]
export const awards = [
  {
    icon: <i class='fa-brands fa-canadian-maple-leaf'></i>,
    num: "",
    name: "Locations throughout Canada",
  },
  {
    icon: <i class='fa-solid fa-briefcase'></i>,
    num: "",
    name: "Creating business partnerships",
  },
  {
    icon: <i class='fa-solid fa-lightbulb'></i>,
    num: "",
    name: "Innovative solutions for your tech",
  },
  {
    icon: <i class='fa-solid fa-forward'></i>,
    num: "",
    name: "Fast support when you need it",
  },
]
export const location = [
  {
    id: 1,
    name: "Victoria, BC",
    Villas: "Remote & Onsite Support Available",
    // Apartments: "10 Apartments",
    // Offices: "07 Offices",
    cover: "./images/location/city-1.png",
  },
  {
    id: 2,
    name: "Vancouver, BC",
    Villas: "Remote & Onsite Support Available",
    // Apartments: " 10 Apartments",
    // Offices: "07 Offices",
    cover: "./images/location/city-3.png",
  },
  {
    id: 3,
    name: "More Coming Soon",
    Villas: "",
    // Apartments: "10 Apartments",
    // Offices: "07 Offices",
    cover: "./images/location/city-2.png",
  },
  
  // {
  //   id: 4,
  //   name: "Vanderhoof, BC",
  //   Villas: "Remote & Onsite Support Available",
  //   // Apartments: " 10 Apartments",
  //   // Offices: "07 Offices",
  //   cover: "./images/location/city-4.png",
  // },
  // {
  //   id: 5,
  //   name: "Canada",
  //   Villas: "Remote Support Available",
  //   // Apartments: " 10 Apartments",
  //   // Offices: "07 Offices",
  //   cover: "./images/location/city-5.png",
  // },
  // {
  //   id: 6,
  //   name: "Rest of World",
  //   Villas: "Remote Support Available",
  //   // Apartments: " 10 Apartments",
  //   // Offices: "07 Offices",
  //   cover: "./images/location/city-6.png",
  // },
]
export const team = [
  {
    list: "50",
    cover: "../images/customer/team-1.jpg",
    address: "Liverpool, Canada",
    name: "Sargam S. Singh",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "70",
    cover: "../images/customer/team-2.jpg",
    address: "Montreal, Canada",
    name: "Harijeet M. Siller",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "80",
    cover: "../images/customer/team-3.jpg",
    address: "Denever, USA",
    name: "Anna K. Young",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "51",
    cover: "../images/customer/team-4.jpg",
    address: "2272 Briarwood Drive",
    name: "Michael P. Grimaldo",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "42",
    cover: "../images/customer/team-5.jpg",
    address: "2272 Briarwood Drive",
    name: "Michael P. Grimaldo",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "38",
    cover: "../images/customer/team-5.jpg",
    address: "Montreal, USA",
    name: "Adam K. Jollio",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
]
export const price = [
  {
    plan: "Basic",
    price: "29",
    ptext: "per user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "120GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "5GB Cloud Storage",
      },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Personal Help Support" },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Enterprise SLA" },
    ],
  },
  {
    best: "Best Value",
    plan: "Standard",
    price: "49",
    ptext: "per user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "150GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "10GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        change: "color",
        icon: <i class='fa-solid fa-x'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Platinum",
    price: "79",
    ptext: "2 user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
]
export const footer = [
  {
    title: "Quick Links",
    text: [{ list: "Home" }, { list: "About" }, { list: "Services" }, { list: "Rates" }, { list: "Terms" }, { list: "Contact" }],
  },
  {
    title: "Resources",
    text: [{ list: "Helpdesk" }, { list: "Training" }],
  },
  // {
  //   title: "COMPANY",
  //   text: [{ list: "About" }, { list: "Blog" }, { list: "Pricing" }, { list: "Affiliate" }, { list: "Login" }, { list: "Changelog" }],
  // },
]
