import React from "react"
import Back from "../common/Back"
import GoToTop from "../common/GoToTop"
import Heading from "../common/Heading"
import img from "../images/about.jpg"
import "./about.css"

const About = () => {
  const url = 'https://bookit.dialageek.ca/userDashboard/bookNow/';
  return (
    <>
      <section className='about'>
        <Back name='About Us' title='Who We Are' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='Dial-A-Geek Story' subtitle='Check out our company story & work process' />
            <p>Established in 2003, our flagship Victoria branch has flourished over the years & services the Greater Victoria, Western Communities, and the Saanich Peninsula.
                <br/><br/>Dial-A-Geek has specialized on providing state-of-the-art IT solutions for small-to-medium-sized businesses, professionals, & residential customers.</p>
            <p>We offer everything from a simple on-site & on-demand repair, to a comprehensive & proactive outsourced IT solution for businesses & professionals.
                <br/><br/>Find out more about a Dial-A-Geek location near you, we look forward to assisting you with all your computer repair needs</p>
            <button onClick={() => window.open(url, '_self')} className='btn2'>Get Support</button>
          </div>
          <div className='right row'>
            <img src='./images/immio.jpg' alt='' />
          </div>
        </div>
      </section>
      <GoToTop />
    </>
  )
}

export default About
