import React from "react"
import Back from "../common/Back"
import GoToTop from "../common/GoToTop"
import Heading from "../common/Heading"
import img from "../images/about.jpg"
import "./service3.css"
import { useHistory } from "react-router-dom";

const Service3 = () => {
  const url = 'https://bookit.dialageek.ca/';
  const history = useHistory();
  const routeChange = () =>{ 
    let path = `/services`; 
    history.push(path);
  }
  return (
    <>
      <section className='about'>
        <Back name='Network Services' title='Home & Business' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
          <div className='backbuttoncontainer'>
              <button className='backbutton' onClick={routeChange}>
              <p><i class="fa-solid fa-circle-arrow-left fa-2xl"></i><span className='backbuttontext'>Back to Services</span></p>
              </button>
            </div>
            <Heading title='Network Services' subtitle='Installations, support, security & upgrades' />
            <p className='backupText'>Business networking is the lifeblood of your organization.
            <br/><br/>If you can’t effectively share, monitor and manage your data, you’re unable to run your business.<br/><br/>
              Dial-A-Geek specializes in business networks and is able to provide the best hardware affordably.<br/>
              Find out how much easier it is run your business when everything is done right.
              <br/><br/><h1><b>Good business networking should provide:</b></h1><br/>
              <b>• Faster network speeds<br/>
              • More reliable transmission of files<br/>
              • Robust backups<br/>
              • Centralized management of all your devices<br/>
              • Effective security to ensure your data isn’t falling into the wrong hands<br/>
              <br/></b>
              It's time for you to get a free audit from Dial-A-Geek for your business networking needs.
              Let our business specialists give you a snapshot of your existing network and practical advice on how to solve any issues.
              </p>
            <button onClick={() => window.open(url, '_self')} className='btn2'>Get Support</button>
          </div>
          <div className='right row'>
            <img src='./images/wifi.png' alt='' className='services3image' />
          </div>
        </div>
      </section>
      <GoToTop />
    </>
  )
}

export default Service3