import React from "react"
import Back from "../common/Back"
import GoToTop from "../common/GoToTop"
import Heading from "../common/Heading"
import img from "../images/about.jpg"
import "./service4.css"
import { useHistory } from "react-router-dom";

const Service4 = () => {
  const url = 'https://bookit.dialageek.ca/';
  const history = useHistory();
  const routeChange = () =>{ 
    let path = `/services`; 
    history.push(path);
  }
  return (
    <>
      <section className='about'>
        <Back name='Security & Antivirus' title='Complete Protection' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
          <div className='backbuttoncontainer'>
              <button className='backbutton' onClick={routeChange}>
              <p><i class="fa-solid fa-circle-arrow-left fa-2xl"></i><span className='backbuttontext'>Back to Services</span></p>
              </button>
            </div>
            <Heading title='Security & Antivirus' subtitle='Antivirus, monitoring, updates & backups' />
            <p className='backupText'><b>Receive An Annual Anti-Virus and Malware Removal Package with Your Service</b><br/>
              <br/>Protect your computer against all malware, virus and online attacks. <br/>Dial-A-Geek is a registered re-seller of NOD32 Anti-Virus, ESET’s award winning Anti-Virus software.<br/><br/>
              <b>PROACTIVE PROTECTION</b> <br/>
              Award winning ThreatSense technology combines multiple layers of detection protecting you from Internet threats before they strike.
              <br/>
              <b><br/><br/>FILE STORAGE SECURITY</b> <br/>
              Sync folders / drives between your PC & online account securely.
              <br/><br/>Need to store physical servers offsite? No problem! with Dial-A-Geek we can provide access to secure offsite servers to meet any demand.
              <b><br/><br/>PRECISE DETECTION</b> <br/>
              ESET accurately identifies known and unknown threats. It consistently wins top awards from testing labs and is recognized for having zero false alarms.
              <br/><br/>
              <b><br/>PERSONAL FIREWALL</b> <br/>
              Prevent unauthorized programs from accessing your network or internet connection. New Learning Mode automatically creates firewall rules by observing user behavior.
              <br/><br/>
              <b><br/>BUILT FOR SPEED</b> <br/>
              ESET software requires less memory and CPU power, allowing your computer to run lightning fast, making more room for games, web browsing, and emailing.
              <br/><br/>
              <b><br/>ANTI-SPAM</b> <br/>
              Separate annoying spam email from your legitimate email.
              <br/><br/>
              </p>
            <button onClick={() => window.open(url, '_self')} className='btn2'>Get Support</button>
          </div>
          <div className='right row'>
            <img src='./images/lock.png' alt='' className='services4image' />
          </div>
        </div>
      </section>
      <GoToTop />
    </>
  )
}

export default Service4